import {
  ButtonPrimaryLink,
  colors,
  ConfettiSectionWrapper,
  Emphasis,
  PageHeader,
  TextBody
} from "@xolvio/xolvio-ui";
import { navigate } from "gatsby";
import React from "react";

export const GetInTouchToutNew = () => (
  <ConfettiSectionWrapper>
    <PageHeader
      style={{
        background: colors.AcceptanceGreenUltimatelyLight,
        textAlign: "center"
      }}
    >
      Get in <Emphasis>touch</Emphasis>
    </PageHeader>
    <TextBody style={{ maxWidth: "100%" }}>
      Take the first step to start solving for the future and request a consultation today.
    </TextBody>
  </ConfettiSectionWrapper>
);
