import React from "react";
import { LayoutDefault } from "../components/LayoutDefault";
import scrollToElement from "scroll-to-element";
import styled from "styled-components";
import {
  ConfettiHR,
  ContactUsNew,
} from "@xolvio/xolvio-ui";
import { GetInTouchToutNew } from "../components/GetInTouchToutNew";

//https://stackoverflow.com/a/13419367
export function parseQuery(queryString) {
  const query = {};
  const pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

export default ({ location }) => {
  const subjects = ["development", "training", "consulting"];
  const anchor = "form-anchor";
  const subject = parseQuery(location.search).subject;
  if (subjects.includes(subject)) {
    setTimeout(() => scrollToElement(`#${anchor}`, { delay: 10 }), 10);
  }

  return (
    <LayoutDefault>
      <GetInTouchToutNew />
      <Anchor id={anchor} />
      <ContactUsNew />
      <ConfettiHR />
    </LayoutDefault>
  );

  // return (
  //   <LayoutDefault>
  //     <CenteredContentWrapper>
  //     <PageHeader
  //       style={{
  //         background: colors.AcceptanceGreenUltimatelyLight,
  //         textAlign: "center"
  //       }}
  //     >
  //       Get in <Emphasis>touch</Emphasis>
  //     </PageHeader>
  //     <TextBody>
  //       Drop us a line or schedule a 30m call at your convenience.
  // 		</TextBody>
  //     {/*<GetInTouchTout />*/}
  //     {/*<Anchor id={anchor} />*/}
  //     <ContactUs searchQuery={location.search} />
  //     {/*<ConfettiHR />*/}
  //     </CenteredContentWrapper>
  //   </LayoutDefault>
  // );
};

const Anchor = styled.div`
  position: relative;
  top: -80px;
`;
